import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_input = _resolveComponent("el-input");

  return _openBlock(), _createBlock(_component_el_input, {
    modelValue: $data.input,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $data.input = $event;
    }),
    rows: 40,
    type: "textarea"
  }, null, 8, ["modelValue"]);
}